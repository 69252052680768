import React, { useEffect, useState } from "react";
import Container from "./container";

import * as styles from "../styles/components/dealers-map.module.css";

const DealersMap = (props) => {

  const [loaded, setLoaded] = useState(false);

  function handleStoreRocket() {
    let config = {
      selector: ".store-locator-widget",
      account: "q5J0N57pBA"
    };

    window.StoreRocket.init(config); 
  }

  useEffect(() => {
    const tag = document.createElement('script');
    tag.src = '//cdn.storerocket.io/widget.js';
    tag.addEventListener('load', ()=>setLoaded(true));
    document.body.appendChild(tag);
  }, []);

  useEffect(() => {
    if(!loaded) return;

    handleStoreRocket();
  }, [loaded]);

  return (
    <div className={styles.root}>
      <Container>
        <div className="store-locator-widget"></div>
      </Container>
    </div>
  );
}

export default DealersMap;
