import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import Container from "../components/container";

import PageHeader from "../components/page-header";
import DealersMap from "../components/dealers-map";

export const query = graphql`
  query DealersPageQuery {
    dealers: sanityDealers {
      title
      description
      featuredImage {
        ...SanityImage
        alt
      }
    }
  }
`;

const DealersPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const dealers = (data || {}).dealers;

  return (
    <Layout>
      <Seo
        title={dealers.title}
        description={dealers.description}
        image={dealers.featuredImage}
      />

      {dealers.title && (<PageHeader title={dealers.title} />)}
      <DealersMap />
    </Layout>
  );
};

export default DealersPage;
